import React, { useState, useEffect } from 'react';
import AppStoreIcon from 'images/apple-app-store-icon.png';
import PlayStoreIcon from 'images/play-store-icon.png';
import CloudDownload from '../../images/illustrations/cloud-download-outline.svg'
import EnterApp from '../../images/illustrations/enter-outline.svg'
import HandLeft from '../../images/illustrations/hand-left-outline.svg'

export default function ComposerAppStoreCreate(props) {
  const appleStoreUrl = props.isApple ? props.url : "";
  const playStoreUrl = props.isGoogle ? props.url : "";

  return (
    <div className="app-store-links w-full max-w-3xl bg-white mx-auto mt-3 mb-4 p-4 pt-2 sm:p-8 shadow-lg rounded-lg text-obelisk text-left">
      <div className="p-4 mb-4 bg-grayLight rounded-lg">
      <h6 className="font-bold text-obelisk">Advanced Options</h6>
        <div className="">
          <label className="inline-flex items-center mb-2 mt-2">
            <input
              type="checkbox"
              disabled={true}
              checked={true}
              readOnly
              id="activate-checkbox"
              className="form-checkbox text-blueGreen advanced-options-checkboxes"
            />
            <span className="ml-2 mr-1 text-blueGreen">Grow my app installs</span>
            <img src={CloudDownload} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.5em'}} />
          </label>
        </div>
        <div className="">
          <label className="inline-flex items-center mb-2">
            <input
              type="checkbox"
              id="activate-checkbox"
              className="form-checkbox text-blueGreen advanced-options-checkboxes"
              checked={props.activateAppDeepLinkingMode}
              onChange={() => props.setActivateAppDeepLinkingMode(!props.activateAppDeepLinkingMode)}
            />
            <span className="ml-2 mr-1 text-blueGreen">Grow my app engagement</span>
            <img src={EnterApp} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.5em'}} />
          </label>
        </div>
      </div>
      <div className="app-store-fallbacks">
        <div className="app-store-box">
          <fieldset>
            <h6 className="text-obelisk font-bold mt-1 mb-2">Enter your App Store URL</h6>
            <div className="input-icon">
              <img
                className="input-icon__icon"
                src={AppStoreIcon}
                alt="iOS Icon"
              />
              <input
                type='text'
                className={`input ${props.isApple ? 'pointer-events-none' : ''} ${props.otherStoreInputErrors && !props.isApple ? 'invalid-input' : ''}`}
                value={props.isApple ? appleStoreUrl : props.otherStoreURL}
                contentEditable={!props.isApple}
                onChange={(e) => props.handleSetOtherStoreUrl(e)}
                placeholder="ex https://apps.apple.com/app-id (optional)"
              />
            </div>
          </fieldset>
          <fieldset>
            <div className="input-icon mt-3">
              <img
                className="input-icon__icon"
                src={PlayStoreIcon}
                alt="Play Store Icon"
              />
              <input
                type='text'
                className={`input ${props.isGoogle ? 'pointer-events-none' : ''} ${props.otherStoreInputErrors && !props.isGoogle ? 'invalid-input' : ''}`}
                value={props.isGoogle ? playStoreUrl : props.otherStoreURL}
                contentEditable={!props.isGoogle}
                onChange={(e) => props.handleSetOtherStoreUrl(e)}
                placeholder="ex https://play.google.com/app-id (optional)"
              />
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  )
}