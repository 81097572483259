import React, { Suspense, Component } from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import ToastGlobal from '../shared/ToastGlobal';
import { toast } from 'react-toastify';

import { disableReactDevTools } from './DisableReactDevTools';

const Show5KModal = React.lazy(() => import(/* webpackChunkName: "show-5k-modal" */ './Show5KModal'));
const ShowPausedModal = React.lazy(() => import(/* webpackChunkName: "show-pause-modal" */ './ShowPausedModal'));
const ShowLockedModal = React.lazy(() => import(/* webpackChunkName: "show-locked-modal" */ './ShowLockedModal'));
const ShowForcedModal = React.lazy(() => import(/* webpackChunkName: "show-forced-modal" */ './ShowForcedModal'));
const Spinner = React.lazy(() => import(/* webpackChunkName: "spinner" */ '../shared/Spinner'));
const TrialMeter = React.lazy(() => import(/* webpackChunkName: "trial-meter" */ '../shared/TrialMeter'));
const PlanClickCountdownMeter = React.lazy(() => import(/* webpackChunkName: "plan-click-countdown-meter" */ '../shared/PlanClickCountdownMeter'));
const UpgradeButton = React.lazy(() => import(/* webpackChunkName: "upgrade-button" */ '../shared/UpgradeButton'));
const DeepLinkTester = React.lazy(() => import(/* webpackChunkName: "DeepLinkTester" */ '../Static/DeepLinkTester'));

export default class AppCtrl extends Component {
  state = {
    currentCompany: null,
    currentUser: null,
    show5KModal: false,
    showPausedModal: false,
    showLockedModal: false,
    showForcedModal: false,
    showSpinner: false,
    showTrialMeter: false,
    showUpgradeButton: false,
    showPlanClickCountdownMeter: false
  }

  componentDidMount = () => {
    if (document.querySelector('#current_company') !== null) {
      const currentCompany = JSON.parse(document.querySelector('#current_company').getAttribute("data"));
      this.setState({ currentCompany }, () => {
        this.validateCompany();
        this.checkShowMeterOrUpgrade();
      });
    if ((process.env.NODE_ENV === "production") && !(process.env.RAILS_ENV.includes("staging"))) disableReactDevTools();
    }

    if (document.querySelector("meta[name='currentUser']") !== null) {
      const currentUser = JSON.parse(document.querySelector("meta[name='currentUser']").getAttribute("content"));
      this.setState({ currentUser });
    }
  }

  validateCompany = () => {
    const company = this.state.currentCompany;

    if (company && company.enterprise !== true ) {
      if (company.show5Kmodal) {
        this.setState({show5KModal: true});
      } else if (company.state === 'paused') {
        this.setState({ showPausedModal: true });
      } else if (company.locked === 'true' || company.locked === true) {
        this.setState({ showLockedModal: true });
      } else if (company.force_credit_card === 'true' || company.force_credit_card === true) {
        this.setState({ showForcedModal: true });
      }
    }
  }

  checkShowMeterOrUpgrade = () => {
    if(!this.state.currentCompany) {
      return;
    }

    let showUpgradeButton = this.state.currentCompany.show_upgrade_modal

    this.setState({ showUpgradeButton });

    if(!showUpgradeButton) {
      let exclude = this.state.currentCompany.enterprise || this.state.currentCompany.waive_in_stripe;
      let remaining = (this.state.currentCompany.free_hit_limit - this.state.currentCompany.hit_count);

      if (!exclude) {
        let hasFreeClicks = remaining > 0;
        let hasNoCCAndClicks = remaining == 0 && !this.state.currentCompany.valid_credit_card;
        let showTrialMeter = hasFreeClicks || hasNoCCAndClicks;

        this.setState({ showTrialMeter });
      }
    }
    if(this.state.currentCompany.plan_click_countdown_meter) {
      this.setState({ showPlanClickCountdownMeter: true });
    }
  }

  dismiss5KModal = () => {
    this.setState({ show5KModal: false })
  }

  dismissForcedModal = () => {
    this.setState({ showForcedModal: false })
  }

  close5KModal = () => {
    this.setState({ show5KModal: false });
    this.getCompany();
  }

  closeForcedModal = () => {
    this.setState({ showForcedModal: false });
    this.getCompany();
  }

  closePausedModal = () => {
    this.setState({ showPausedModal: false });
    this.getCompany();
  }

  closeLockedModal = () => {
    this.setState({ showLockedModal: false });
    this.getCompany();
  }

  getCompany = () => {
    axios.get(`/api/internal/companies/${this.state.currentCompany.id}`)
      .then(res => {
        this.setState({ currentCompany: res.data }, () => {
          this.checkShowMeterOrUpgrade();
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  patchCompany = (hash, successMsg, errorMsg) => {
    axios.patch(`/api/internal/companies/${this.state.currentCompany.id}`, hash)
      .then(res => {
        if(successMsg) {
          toast.success(successMsg);
        }

        this.setState({ currentCompany: res.data }, () => {
          this.checkShowMeterOrUpgrade();
        });
      })
      .catch(error => {
        console.log(error);

        if(errorMsg) {
          toast.error(errorMsg)
        }
      });
  }

  toggleSpinner = () => {
    this.setState({showSpinner: !this.state.showSpinner});
  }

  render() {
    return (
      <Suspense fallback="">
        <DeepLinkTester />
        {this.state.currentCompany &&
          <>
            { this.state.showSpinner ? <Spinner /> : null }

            {this.state.show5KModal &&
              <Show5KModal
                company={this.state.currentCompany}
                show5KModal={this.state.show5KModal}
                dismiss5KModal={this.dismiss5KModal}
                close5KModal={this.close5KModal}
              />
            }

            {this.state.showPausedModal &&
              <ShowPausedModal
                company={this.state.currentCompany}
                showPausedModal={this.state.showPausedModal}
                closePausedModal={this.closePausedModal}
              />
            }

            {this.state.showLockedModal &&
              <ShowLockedModal
                company={this.state.currentCompany}
                showLockedModal={this.state.showLockedModal}
                closeLockedModal={this.closeLockedModal}
              />
            }

            {this.state.showForcedModal &&
              <ShowForcedModal
                company={this.state.currentCompany}
                showForcedModal={this.state.showForcedModal}
                dismissForcedModal={this.dismissForcedModal}
                closeForcedModal={this.closeForcedModal}
              />
            }

            {this.state.showTrialMeter &&
              <TrialMeter
                show={this.state.showTrialMeter}
                company={this.state.currentCompany}
                creditCardSubmit={this.getCompany}
              />
            }
            {this.state.showPlanClickCountdownMeter &&
              <PlanClickCountdownMeter
                show={this.state.showPlanClickCountdownMeter}
                company={this.state.currentCompany}
              />
            }

            {this.state.showUpgradeButton &&
              <UpgradeButton
                show={this.state.showUpgradeButton}
                company={this.state.currentCompany}
                user={this.state.currentUser}
                patchCompany={this.patchCompany}
              />
            }
          </>
        }
      </Suspense>
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  render(<AppCtrl />, document.querySelector('#app'));
});
