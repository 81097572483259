import React, { useState, useEffect, useRef, Suspense } from 'react';
import axios from 'axios';
import { toast } from "react-toastify"
import ReactTooltip from 'react-tooltip';
import useFirstRender from '../../utils/hooks/useFirstRender';
import useDebounce from '../../utils/hooks/useDebounce';
import {
  urlValidator,
  containsWhiteSpace,
  containsSafeURLCharacters,
} from '../../utils/urlHelpers';

const LoginModal = React.lazy(() => import(/* webpackChunkName: "login-modal" */ './LoginModalNew'));
import Spinner from '../shared/Spinner';
import Sparkles from '../../images/icons/purple-pink-gradient-sparkles.svg';
import OutlineSparkles from '../../images/icons/outline-sparkles.svg';
import PencilCustomize from '../../images/icons/purple-pink-gradient-customize-pencil.svg';
import TuneCustomize from '../../images/icons/tune-sliders-customize.svg';
import ComposerMarketplaceMatch from '../shared/ComposerMarketplaceMatch';
import ComposerAppStoreCreate from './ComposerAppStoreCreate';

export default function HomePageComposer() {
  const [loading, setLoading] = useState(false)
  const [createLinkLoading, setCreateLinkLoading] = useState(false)
  const [disabledCreate, setDisabledCreate] = useState(false)
  const [url, setUrl] = useState()
  const [isStoreUrl, setIsStoreUrl] = useState(false)
  const [isApple, setIsApple] = useState(false)
  const [isGoogle, setIsGoogle] = useState(false)
  const [testUrlResponse, setTestUrlResponse] = useState({})
  const [urlGeniusLink, setUrlGeniusLink] = useState("")
  const [domainAndShort, setDomainAndShort] = useState("")
  const [urlGeniusLinkPath, setUrlGeniusLinkPath] = useState("")
  const [urlGeniusLinkPresent, setUrlGeniusLinkPresent] = useState(false)
  const [pathCheckInProgress, setPathCheckInProgress] = useState(false)
  const [pathTaken, setPathTaken] = useState(false)
  const [pathValid, setPathValid] = useState(true)
  const [activateAppDeepLinkingMode, setActivateAppDeepLinkingMode] = useState(false)
  const [otherStoreUrl, setOtherStoreUrl] = useState("")
  const [otherStoreInputErrors, setOtherStoreInputErrors] = useState(false)
  const [amznUnfurledUrl, setAmznUnfurledUrl] = useState(null)
  const [amznUnfurlFailed, setAmznUnfurlFailed] = useState(false)
  const [appIconUrl, setAppIconUrl] = useState(null)
  const [newLinkCreated, setNewLinkCreated] = useState(false)
  const [marketplaceMatch, setMarketplaceMatch] = useState(false)
  const [composeAsMarketplaceLink, setComposeAsMarketplaceLink] = useState(false)
  const [inputKey, setInputKey] = useState(Date.now());
  const [inputErrors, setInputErrors] = useState({
    hasUnsafeChars: false,
    hasSpaces: false,
    hasBadUrl: false,
  })
  const [pathInputErrors, setPathInputErrors] = useState({
    hasUnsafeChars: false,
    hasSpaces: false,
  })
  const [amazonDoubleDippingWarning, setAmazonDoubleDippingWarning] = useState(false)
  const [mavelyPoweredByUrlGenius, setMavelyPoweredByUrlGenius] = useState(false)

  const [showLoginModal, setShowLoginModal] = useState(false)

  const checkDocForCurrentUser = () => {
    let user = document.querySelector("meta[name='currentUser']")
    if(user){
      return JSON.parse(user.getAttribute('content'))
    }
  }
  const [currentUser, setCurrentUser] = useState(checkDocForCurrentUser())

  const checkDocForCurrentCompany = () => {
    let company = document.querySelector("#current_company")
    if(company){
      return JSON.parse(company.getAttribute('data'))
    }
  }
  const [currentCompany, setCurrentCompany] = useState(checkDocForCurrentCompany())

  const debounceValue = useDebounce(url, 1000);
  const debouncePath = useDebounce(urlGeniusLinkPath, 400)

  const firstRender = useFirstRender();
  const prevXHR = useRef(null);
  const prevPathXHR = useRef(null);

  useEffect(() => {
    if(!firstRender) {
      setUrlGeniusLink(buildUrlGeniusLinkFromResponse())
      setDomainAndShort(buildDomainAndShort())
      setUrlGeniusLinkPresent(true)
    }
  },[testUrlResponse])

  useEffect(() => {
    if(!firstRender) {
      homePageComposerOnChangeHandler(debounceValue)
    }
  }, [debounceValue])

  useEffect(() => {
    if(!firstRender) {
      homePageComposerPathOnChangeHandler(debouncePath)
    }
  }, [debouncePath])

  useEffect(() => {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  })

  const scrollForUser = () => {
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop === 0) {
      window.scrollTo({
        top: 380,
        behavior: "smooth"
      });
    }
  };

  const buildUrlGeniusLinkFromResponse = () => {
    if(Object.keys(testUrlResponse).length > 0){
      if(testUrlResponse.app_map.id != 5){
        return testUrlResponse.p + testUrlResponse.path.split("/")[1]
      }else{
        return testUrlResponse.p + testUrlResponse.short
      }
    }
  }

  const formatWhitelabel = (domain) => {
    // remove http or https and :// from the domain
    domain = domain.replace(/(^\w+:|^)\/\//, '');
    // make sure that if there is a trailing slash, it is removed
    if(domain.charAt(domain.length - 1) == "/"){
      return domain.slice(0, -1)
    }else{
      return domain
    }
  }

  const buildDomainAndShort = () => {
    let hasBrandedDomain
    let isStoreUrl = testUrlResponse.app_map.id == 1196 || testUrlResponse.app_map.id == 1197
    let isCustomAppMap = testUrlResponse.app_map.id == 5
    if(!currentCompany) {
      hasBrandedDomain = false
    }else{
      hasBrandedDomain = currentCompany.whitelabel_domain != "" && currentCompany.whitelabel_domain != null
    }
    if(hasBrandedDomain){
      if(isCustomAppMap || isStoreUrl){
        return formatWhitelabel(currentCompany.whitelabel_domain) + "/"
      }else{
        return formatWhitelabel(currentCompany.whitelabel_domain) + "/" + testUrlResponse.path.split("/")[0] + "/"
      }
    }else{
      if(isStoreUrl){
        return "https://urlgeni.us/"
      }else{
        return testUrlResponse.p
      }
    }
  }

  const urlNotAllowed = (val) => {
    return val.includes("urlgeni.us") || urlIsAmazonAco(val)
  }

  const updateShortLinkAndPath = (shortString, testPath) => {
    setUrlGeniusLinkPath(shortString)
    setTestUrlResponse({
      ...testUrlResponse,
      short: shortString,
      path: testPath
    })
  }

  const loginModalUrl = () => {
    // ensures the url object passed to the login modal creation has everything it needs
    let linkCreationObject = testUrlResponse

    if(isStoreUrl) {
      let storeParams = {
        'isStoreUrl': true,
        'fallback': url,
        'path': testUrlResponse.short,
        'open_store_link_in_app': activateAppDeepLinkingMode,
        'ios': isApple ? url : otherStoreUrl,
        'android': isGoogle ? url : otherStoreUrl,
      };
      linkCreationObject = {...linkCreationObject, ...storeParams};
    } else {
      linkCreationObject["url"] = url.trim();
    }

    return linkCreationObject;
  }

  const checkDataForAmznUnfurlFailed = (data) => {
    let ios_scheme = data.ios_scheme
    if(ios_scheme){
      return ios_scheme.includes("ErrorPleaseTryAgain") || ios_scheme == "com.amazon.mobile.shopping.web://amazon.com/?"
    }
    return false
  }

  const checkPathValidity = (value) => {
    let noCurrentPathErrors = (!pathInputErrors.hasUnsafeChars && !pathInputErrors.hasSpaces && pathValid && !pathTaken)
    if(value.length == 0 || (value == testUrlResponse.short && noCurrentPathErrors)){
      setPathValid(true)
      setPathTaken(false)
      return
    }
    if (prevPathXHR.current) {
      prevPathXHR.current.cancel();
    }
    prevPathXHR.current = axios.CancelToken.source();

    setPathCheckInProgress(true)
    setPathTaken(false)
    let testPath;
    let shortString = value;
    let regex = /[^a-zA-Z0-9\;\-\:\_]/;

    let appPath = testUrlResponse.p.match(/.us\/(.*)/)[1];
    testPath = `${appPath}${shortString}`;
    if (shortString.length > 3 && !regex.test(shortString)) {
      setPathValid(true)
      axios.post(`/api/internal/test_path`, { path: testPath, cancelToken: prevPathXHR.current.token} )
        .then(res => {
          setPathCheckInProgress(false)
          setPathTaken(!(res.status == 200))
          updateShortLinkAndPath(shortString, testPath);
        })
        .catch(error => {
          setPathTaken(true)
          setPathCheckInProgress(false)
        })
      }
    else {
      setPathValid(false)
      setPathCheckInProgress(false)
    }
  }

  const createTestUrl = (value) => {
    setLoading(true)
    if (prevXHR.current) {
      prevXHR.current.cancel();
    }
    prevXHR.current = axios.CancelToken.source();

    let postURL = `/api/internal/test_url`

    if(currentCompany?.creator_marketplace){
      postURL = `/api/internal/test_url?marketplace_match_check=true`
    }
    axios.post(postURL, {url: value, cancelToken: prevXHR.current.token})
    .then(res => {
      if(res.data.error){
        setInputErrors({
          ...inputErrors,
          hasBadUrl: true
        })
      }else{
        setAmznUnfurledUrl(res.data.amazon_unfurled_url)
        setTestUrlResponse(res.data)
        setUrlGeniusLinkPath(res.data.short)
        setAppIconUrl(res.data.app_icon_url)
        setLoading(false)
        setMavelyPoweredByUrlGenius(res.data.app_map_id == 1692)
        setAmznUnfurlFailed(checkDataForAmznUnfurlFailed(res.data))
        setAmazonDoubleDippingWarning(res.data.amazon_double_dipping_tags_warning)
        if(res.data.marketplace_match){
          scrollForUser()
          setMarketplaceMatch(res.data.marketplace_match)
        }
        if(res.data.app_map_id == 1196 || res.data.app_map_id == 1197){
          setIsApple(res.data.app_map_id == 1196)
          setIsGoogle(res.data.app_map_id == 1197)
          setIsStoreUrl(true)
          scrollForUser()
        }
      }
    })
    .catch( e =>{
      console.log(e)
      setInputErrors({
        ...inputErrors,
        hasBadUrl: true
      })
      setLoading(false)
    })
  }

  const checkForMissingSlashAndAdd = (value) => {
    try {
      const hasProtocol = value.startsWith('http://') || value.startsWith('https://');
      const url = hasProtocol ? new URL(value) : new URL('http://' + value);
      if (!url.pathname || url.pathname === '/') {
        url.pathname = '/';
      }
      return hasProtocol ? url.toString() : url.toString().replace('http://', '');
    } catch (e) {
      return value;
    }
  }

  const composerOnChangeStateReset = () => {
    setIsStoreUrl(false)
    setPathInputErrors({
      hasUnsafeChars: false,
      hasSpaces: false,
    })
    setPathValid(true)
    setPathTaken(false)
    setOtherStoreUrl("")
    setAppIconUrl(null)
    setUrlGeniusLink("")
    setMarketplaceMatch(false)
    setUrlGeniusLinkPresent(false)
    setMavelyPoweredByUrlGenius(false)
    setAmznUnfurlFailed(false)
    setAmazonDoubleDippingWarning(false)
  }

  const homePageComposerOnChangeHandler = (val) => {
    composerOnChangeStateReset()
    let value = val.trim()
    value = checkForMissingSlashAndAdd(value)
    const hasUnsafeChars = !containsSafeURLCharacters(value);
    const hasSpaces = containsWhiteSpace(value);
    const hasBadUrl = !urlValidator(value);

    setInputErrors({
      hasUnsafeChars,
      hasSpaces,
      hasBadUrl,
    })

    if (hasUnsafeChars || hasSpaces || hasBadUrl) {
      if (value.length == 0) {
        setInputErrors({
          hasUnsafeChars: false,
          hasSpaces: false,
          hasBadUrl: false
        })
      }
      setLoading(false)
    }else if(urlNotAllowed(value)){
      setInputErrors({
        hasUnsafeChars: false,
        hasSpaces: false,
        hasBadUrl: true
      })
      setLoading(false)
    }else{
      createTestUrl(value)
    }
  }

  const homePageComposerPathOnChangeHandler = (val) => {
    const value = val.trim()
    const hasUnsafeChars = !containsSafeURLCharacters(value);
    const hasSpaces = containsWhiteSpace(value);

    setPathInputErrors({
      hasUnsafeChars,
      hasSpaces,
    })

    if (value.length == 0) {
      setPathInputErrors({
        hasUnsafeChars: false,
        hasSpaces: false,
      })
      setPathTaken(false)
    }
    if(!(hasUnsafeChars && hasSpaces)) {
      checkPathValidity(value)
    }
  }

  const handleSetOtherStoreUrl = (e) => {
    let value = e.target.value
    const hasUnsafeChars = !containsSafeURLCharacters(value);
    const hasSpaces = containsWhiteSpace(value);
    setOtherStoreUrl(value)
    setOtherStoreInputErrors(hasUnsafeChars || hasSpaces)
  }


  const urlIsAmazonAco = (url) => {
    let urlWithoutProtocol = url.replace(/(^\w+:|^)\/\//, '');
    let splitURl = urlWithoutProtocol.split("a.co/");
    let isAmazonAco = (urlWithoutProtocol.includes("a.co/") && (splitURl[0] == "" || splitURl[0] == "www."))
    if(isAmazonAco){
      toast.error("Please enter a different destination URL. Linking to this domain is not supported.", { autoClose: 5000 })
    }
    return isAmazonAco
  }

  const handleComposeClicked = (e) => {
    e.stopPropagation();
    if(currentUser){
      if(isStoreUrl){
        createStoreLink()
      }else{
        createLink()
      }
    }else{
      setDisabledCreate(true)
      setShowLoginModal(true)
    }
  }

  const cancelLoginModal = () => {
    // on request close per modal
    setShowLoginModal(false)
    setDisabledCreate(false)
  }

  const closeLoginModal = (user) => {
    // on successful form submission
    setShowLoginModal(false)
    setDisabledCreate(false)
    resetComposer()
  }

  const resetComposer = () => {
    setUrl("")
    setInputKey(Date.now()); // used to reset the input field
    setUrlGeniusLink("")
    setUrlGeniusLinkPath("")
    setUrlGeniusLinkPresent(false)
    setPathCheckInProgress(false)
    setPathTaken(false)
    setPathValid(true)
    setAmznUnfurlFailed(false)
    setAppIconUrl(null)
    setNewLinkCreated(false)
    setMarketplaceMatch(false)
    setComposeAsMarketplaceLink(false)
    setInputErrors({
      hasUnsafeChars: false,
      hasSpaces: false,
      hasBadUrl: false,
    })
    setPathInputErrors({
      hasUnsafeChars: false,
      hasSpaces: false,
    })
    setAmazonDoubleDippingWarning(false)
    setMavelyPoweredByUrlGenius(false)
  }

  const handleSetCurrentUser = (user) => {
    setCurrentUser(user)
    if(isStoreUrl){
      createStoreLink()
    }else{
      createLink()
    }
  }

  const handleComposeAsMarketplaceLinkChange = () => {
    setComposeAsMarketplaceLink(!composeAsMarketplaceLink)
  }

  const createLink = () => {
    setCreateLinkLoading(true)
    let paramsToCreateLink = testUrlResponse
    paramsToCreateLink["amazon_unfurled_url"] = amznUnfurledUrl || ""
    paramsToCreateLink["url"] = url
    axios.post(`/api/internal/links/ui_create_social_link`, { url: paramsToCreateLink, compose_as_marketplace_link: composeAsMarketplaceLink })
      .then(res => {
        if(res.data.id){
          const link_id = res.data.id
          window.location = `/links/${link_id}#routing`
        }else{
          toast.error("Something went wrong. Please try again.", { autoClose: 4000 })
          setCreateLinkLoading(false)
        }
      })
      .catch(error => {
        toast.error("Something went wrong. Please try again.", { autoClose: 4000 })
        setDisabledCreate(false)
        setCreateLinkLoading(false)
      })
  }

  const createStoreLink = () => {
    setCreateLinkLoading(true)
    let params = {
      'created_by': 'ui',
      'fallback': '',
      'path': urlGeniusLinkPath,
      'open_store_link_in_app': activateAppDeepLinkingMode,
      'user_entered_url' : url
    };
    params.ios = isApple ? url : otherStoreUrl;
    params.android = isGoogle ? url : otherStoreUrl;

    axios.post(`/api/internal/links/create_appstore_link`, params)
      .then(res => {
        const link_id = res.data.id
        if (activateAppDeepLinkingMode) {
          window.location = `/links/${link_id}#routing`;
        }
        else {
          window.location = `/links/${link_id}`;
        }
      })
      .catch(error => {
        console.log(error.response);
        setCreateLinkLoading(false)
      })
  }

  const disableCreateAndSetUrlGeniusLinkPath = (val) => {
    setPathCheckInProgress(true)
    setUrlGeniusLinkPath(val)
  }

  const badPath = (
    (!pathValid || pathInputErrors.hasUnsafeChars || pathInputErrors.hasSpaces) &&
    !pathCheckInProgress &&
    urlGeniusLinkPath !== ""
  );

  const someInputErrors = Object.values(inputErrors).some(item => item === true);

  const displayErrorMessage = someInputErrors || (amznUnfurlFailed && urlGeniusLinkPresent);

  const pathError = pathTaken || badPath;

  const disabledCreateButton = (
    pathError ||
    pathCheckInProgress ||
    disabledCreate ||
    amznUnfurlFailed ||
    someInputErrors ||
    !urlGeniusLinkPresent ||
    !urlGeniusLinkPath ||
    !url ||
    (isStoreUrl && otherStoreInputErrors)
  );

  const composerHeading = loading
    ? "Composing app link..."
    : urlGeniusLinkPresent
      ? "Customize Your Link"
      : "Compose your intelligent app link";

  const gradientIcon = urlGeniusLinkPresent ? PencilCustomize : Sparkles;

  const showIconBox = urlGeniusLinkPresent || loading;

  const inputBoxImage = urlGeniusLinkPresent ? (
    <img src={appIconUrl} className="" />
  ) : (
    <div className="placeholder-box"></div>
  );

  return (
    <Suspense fallback="">
      {createLinkLoading ? <Spinner /> : null}
      {showLoginModal && 
        <LoginModal
          cancelLoginModal={cancelLoginModal}
          closeLoginModal={closeLoginModal}
          url={loginModalUrl()}
          showLoginModal={showLoginModal}
          setCurrentUser={handleSetCurrentUser}
        />
      }
      <div className="composer-input-heading flex items-center justify-center mb-4">
        <img src={gradientIcon} alt="Auto Awesome aka Sparkle Emoji!" className="mr-2 text-blueGreen"/>
        <h3 className='text-stone composer-input-logged-out__heading' style={{margin: "0px"}}>
          {composerHeading}</h3>
      </div>
      <div className={`icon-and-input-wrapper flex ${marketplaceMatch ? 'mb-2' : ''}`}>
        <div className="flex-grow">
          <form className="composer-form composer-form-homepage">
            <input
              type="url"
              id="url-input"
              placeholder='Get started here! Enter a URL...'
              className={`input composer-input composer-input-logged-out__input ${displayErrorMessage && 'invalid-input'}`}
              defaultValue={url || ""}
              key={inputKey}
              onChange={(e)=> setUrl(e.target.value)}
            />
          </form>

          <div className="composer-error-wrapper">
            {inputErrors.hasSpaces &&
              <p className="home-page-composer-error">
                You have some white space in the entered URL. Please remove the white space to continue.
              </p>
            }
            {inputErrors.hasUnsafeChars &&
              <p className="home-page-composer-error">
                Some characters in the entered URL are not allowed. Please remove them to continue.
              </p>
            }
            {inputErrors.hasBadUrl && !inputErrors.hasSpaces && !inputErrors.hasUnsafeChars &&
              <p className="home-page-composer-error">
                Invalid URL. Please enter a valid URL to continue.
              </p>
            }
          </div>
        </div>
        
        {showIconBox && !displayErrorMessage &&
          <div className='composer-icon'>{inputBoxImage}</div>
        }
        
      </div>
      {amznUnfurlFailed && urlGeniusLinkPresent &&
        <p className="home-page-composer-error">
          We were unable to unfurl your Amzn shortlink. Please try again.
        </p>
      }
      {amazonDoubleDippingWarning && urlGeniusLinkPresent &&
        <p className="home-page-composer-error composer-warning">
          Warning: your Amazon URL contains both an Affiliate tag and an Attribution tag, which may violate Amazon's duplicate commission policy.
        </p>
      }
      {mavelyPoweredByUrlGenius && urlGeniusLinkPresent &&
        <p className="composer-info mt-2 text-blueGreen" style={{fontSize: "14px", fontWeight: "400"}}>
          Note: this URL is already optimized by URLgenius.
        </p>
      }
      {marketplaceMatch && !amazonDoubleDippingWarning &&
        <ComposerMarketplaceMatch
          marketplaceMatch={marketplaceMatch}
          handleComposeAsMarketplaceLink={handleComposeAsMarketplaceLinkChange}
          composeAsMarketplaceLink={composeAsMarketplaceLink}
          homePageComposer
        />
      }
      {isStoreUrl && !displayErrorMessage &&
        <ComposerAppStoreCreate
          url={url}
          testUrlResponse={testUrlResponse}
          activateAppDeepLinkingMode={activateAppDeepLinkingMode}
          handleSetOtherStoreUrl={(e) => handleSetOtherStoreUrl(e)}
          otherStoreUrl={otherStoreUrl}
          setActivateAppDeepLinkingMode={setActivateAppDeepLinkingMode}
          otherStoreInputErrors={otherStoreInputErrors}
          isApple={isApple}
          isGoogle={isGoogle}
        />
      }
      {urlGeniusLinkPresent && !displayErrorMessage &&
        <div className="path-customize box">
          <div className="path-customize__label flex mb-4 self-start">
            <img id="tune-customize-slider-icon" src={TuneCustomize} alt="Tune slider customize icon" />
            <label htmlFor="path-input" className="text-stone" style={{fontSize: "15px"}}>Customize the end of your link with a campaign name or unique identifier</label>  
          </div>
          <form
            className='flex form-container'
            onSubmit={(e) => {
              e.preventDefault();
              handleComposeClicked(e);
            }}
          >
            <div className="domain-and-input flex w-full">
              {domainAndShort &&
                <p className="composer-link-homepage__link text-black text-lg" style={{margin: "auto", padding: ".25rem", whiteSpace: "nowrap"}}>
                  {domainAndShort}
                </p>
              }
              <input
                type="text"
                placeholder='custom'
                className={`input composer-input-logged-out__input ${pathError && 'invalid-input'}`}
                defaultValue={urlGeniusLinkPath || ""}
                onChange={(e) => disableCreateAndSetUrlGeniusLinkPath(e.target.value)}
                style={{ padding: "0.4rem 0.4rem", marginLeft: "0.2rem" }}
              />
            </div>
            <button
              id="composer-link-homepage-button"
              className={`flex btn fill--new accent ${disabledCreateButton && 'disabled pointer-events-none'}`}
              type="submit"
              disabled={disabledCreateButton}
            >
              <img
                src={OutlineSparkles}
                alt="White outline Sparkle Emoji!"
                className="mr-2 text-blueGreen"
              />
              Compose Link
            </button>
          </form>
          {pathError &&
            <p className="home-page-composer-error mt-2">
              {pathTaken ? "This path is already taken. Please choose another." : "Please enter a valid path."}
            </p>
          }
        </div>
      }
    </Suspense>

  )
}