import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import { toast } from "react-toastify"
import ReactTooltip from 'react-tooltip';
import GlobalInfluencerMarketingAward from '../../images/GIMA24GoldAward.svg';
import IncFiveHundredWinner from '../../images/IncColorMedallion.svg';
import HomePageComposer from './HomePageComposer';
import AwardMobileImage from '../../images/awards-sm.svg';

const details = [
  <p>Watch <strong>conversions</strong> rocket up to <strong>9x</strong></p>,
  <p>Instantly build <strong>thousands</strong> of deep links!</p>,
  <p>App attribution at scale <strong>without SDKs</strong></p>,
  <p><strong>For all</strong> creators, sellers, apps!</p>,
  <p><strong>Deep links</strong> for any app, any channel</p>,
];

const cycleDetails = (details) => {
  let currentIndex = 1;
  let cycleCount = 0;
  const maxCycles = 2;
  const tileElement = document.querySelector('.details.tile');
  tileElement.classList.add('pulse');
  
  setTimeout(() => {
    tileElement.classList.remove('pulse');
  }, 3500);
  const interval = setInterval(() => {
    const detailsElements = document.querySelectorAll('.detail');
    detailsElements.forEach((el) => {
      el.classList.remove('hide');
    });
    detailsElements.forEach((el, index) => {
      const isCurrent = index === currentIndex;
      const isLastCycle = cycleCount >= maxCycles - 1;
      const isLastDetail = currentIndex === details.length - 1;

      if (isCurrent) {
        el.classList.add('show');
        el.classList.remove('hide');
      } else {
        if (el.classList.contains('show')) {
          el.classList.remove('show');
          el.classList.add('hide');
        }
      }

      tileElement.classList.add('pulse');
      setTimeout(() => {
        tileElement.classList.remove('pulse');
      }, 3500);
      el.classList.toggle('done', isCurrent && isLastCycle && isLastDetail);
    });

    currentIndex = (currentIndex + 1) % details.length;
    if (currentIndex === 0) {
      cycleCount++;
      if (cycleCount >= maxCycles) {
        clearInterval(interval);
      }
    }
  }, 5000);
  return () => clearInterval(interval);
};

export default function ComposerSection() {
  useEffect(() => {
    const cleanup = cycleDetails(details);
    return cleanup;
  }, []);

  return(
    <div className="text-white relative z-10 flex flex-col composer-hero-logged-out">
      <div className="hero-cta">
        <img className="awards-mobile" src={AwardMobileImage} alt="" />
        <h1 className="hero-cta-text">Award Winning App Links<br/>Watch Conversions Soar!</h1>
        <h1 className="hero-cta-text-mobile">Award Winning App Links. Watch Conversions Soar!</h1>
      </div>
      <div className="details-and-awards">
        <div className="details tile pulse">
          {details.map((detail, index) => (
            <div key={index} className={`detail ${index === 0 ? 'show' : ''}`}>
              {detail}
            </div>
          ))}
        </div>
        <div className="awards">
          <div className="award award-1 tile">
            <img src={GlobalInfluencerMarketingAward} alt="URLgenius Global Influencer Marketing Award 2024 Gold" className="award-image" />
            <div className="award-label">2024 GIMA Gold</div>
          </div>
          <div className="award award-2 tile">
            <img src={IncFiveHundredWinner} alt="Inc Five Thousand Award Winner 2024" className="award-image" />
            <div className="award-label">Inc. 5000 Winner!</div>
          </div>
        </div>
      </div>
      <div className="mt-4 composer-input-logged-out__wrapper box">
        <HomePageComposer />
      </div>
    </div>
  )
}

document.addEventListener('readystatechange', (event) => {
  if (document.readyState === "complete") {
    const container = document.querySelector('#new-composer-section-logged-out');
    if(container) {
      render(<ComposerSection />, container);
    }
  }
});