/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//---------------------------
// SECTION: Stylesheet

//-- Tailwind Base
import 'tailwindcss/base';
import 'tailwindcss/components';
import 'tailwindcss/utilities';

//-- Critical
import '../css/tailwind/base.css'

//-- Base
import '../css/base/fonts.css'
import '../css/base/typography.css'

//-- Elements
import '../css/elements/buttons.css'
import '../css/elements/headers.css'
import '../css/elements/hero.css'

//-- Elements
import '../css/elements/headers.css'
import '../css/elements/hero.css'
import '../css/elements/inputs.css'
import '../css/elements/tooltips.css'
import '../css/elements/modals.css'
import '../css/elements/boxes.css'
import '../css/elements/toasts.css'
import '../css/elements/accordions.css'
import '../css/elements/tags.css'
import '../css/elements/oval_icons.css'
import '../css/elements/blockquotes.css'
import '../css/elements/footers.css'

//-- Components
import '../css/components/composer.css'
import '../css/components/influencer_composer.css'
import '../css/components/creator_marketplace.css'
import '../css/components/spinner.css'
import '../css/components/upsell_modal.css'
import '../css/components/trial_meter.css'
import '../css/components/click_plan_meter.css'
import '../css/components/tabs.css'
import '../css/components/monthly-upgrade.css'
import '../css/components/quotes.css'
import '../css/components/brand_marquee.css'
import '../css/components/scheme-info-modal.css'
import '../css/components/sign-in-or-sign-up.css'
import '../css/components/composer-support-modal.css'
import '../css/components/force_CC_modal.css'
import '../css/components/enterprise_app_deep_links.css'
import '../css/components/driving_results.css'
import '../css/components/intelligent_tools.css'
import '../css/components/case_studies.css'

//-- Pages
import '../css/pages/error_404.css'

// Vendor CSS
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-toastify/dist/ReactToastify.min.css'




//---------------------------
// SECTION: JavaScript

// jQuery
import 'jquery';
import 'jquery-ujs';

window.jQuery = $;
window.$ = $;

import '../components/App/AppCtrl.jsx';
import '../components/shared/Messages';
import '../components/shared/MaintenanceBanner';

import '../utils/routeOldPages.js';
import '../utils/RollbarErrorTracking.js';
import '../utils/navScroll.js';
import '../utils/lazyLoadImages.js';

import "../page/marketing"
import "../page/logged_in";

import "./easy.qrcode.premium.min.js"
