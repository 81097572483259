//---------------------------
// SECTION: Stylesheet

import '../css/pages/marketing/index.css'
import '../css/pages/marketing/app_linking.css'
import '../css/pages/marketing/pricing.css'
import '../css/pages/marketing/smartbanners.css'
import '../css/pages/marketing/deferred_linking.css'
import '../css/pages/marketing/qr_strategy_guide.css'
import '../css/pages/marketing/cmo_paper.css'
import '../css/pages/marketing/blog.css'
import '../css/pages/marketing/solutions.css'

import '../css/components/app_list.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

//---------------------------
// SECTION: Javascript

const bodyClasses = document.body.classList;

// NOTE: Load the composer here so that it manifest right away
// without having to wait. It's a trade off from speed (eg. dynamic load)
// vs the experience of seeing it manifest faster rather than wait for the
// the additional downloa of the JS files
import '../components/Composer/Composer';
import '../components/Composer/ComposerSection';

if (bodyClasses.contains('qr_strategy_guide') || bodyClasses.contains('qr_ebook') || bodyClasses.contains('cmo_paper')) {
  import(/* webpackChunkName: "Lead-Gen-Form" */ '../components/Static/LeadGenForm');
}

if (bodyClasses.contains('app_linking')) {
  import(/* webpackChunkName: "How-It-Works" */ '../components/Static/HowItWorksSlider');
}

const node = document.getElementById('current_company');
const company = JSON.parse(node.getAttribute('data'));

// We can dynamically load this one in since it's at the end of the
// page and not really in view for the most part unless the user scroll
import(/* webpackChunkName: "Case-Study-Slider" */ '../components/Static/CaseStudy/CaseStudySlider');
import(/* webpackChunkName: "Case-Study-Carousel" */ '../components/Composer/CaseStudyCarousel');

// IF the usre is here then laod this in
if (company === null) {
  import(/* webpackChunkName: "Quotes-Slider" */ '../components/Static/QuotesSlider');
}
